<template>
  <div class="row flex-wrap">
    <div class="col-sm-7 col-12">
      <p
        v-if="text"
        class="DrivingLicenseUploaderComponent__text emobg-font-line-height-large"
        v-html="text"
      />
    </div>
    <div class="col-sm-5 col-12 d-flex justify-content-end">
      <ui-file-drop
        :max-size="BYTES_PER_UNIT.megaByte"
        class="DrivingLicenseUploaderComponent__uploader full-width full-height"
        @filechanged="({ detail }) => $emit('filechanged', detail)"
      >
        <div class="emobg-color-primary emobg-font-small mt-2">
          {{ $t('complete_documentation.manual_upload.upload_driving_license.upload') }}
        </div>
      </ui-file-drop>
    </div>
  </div>
</template>

<script>
import { BYTES_PER_UNIT } from '@emobg/web-utils';

export default {
  name: 'DrivingLicenseUploaderComponent',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  created() {
    this.BYTES_PER_UNIT = BYTES_PER_UNIT;
  },
};

</script>
