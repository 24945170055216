<template>
  <ui-validate @status="({ detail }) => $emit('on:validate', detail.isValid)">
    <ui-text-input
      v-validate.blurinput="{
        isRequired: {
          message: $t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),
          whiteSpaceMessage: $t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),
        },
        isBetweenValidLength,
      }"
      :value="value"
      :placeholder="$t('complete_documentation.manual_upload.check_driving_license.dvla_input_placeholder')"
      name="dvla"
      @changevalue="({ detail }) => $emit('update:value', detail)"
    />
  </ui-validate>
</template>

<script>
import size from 'lodash/size';
import { Validate } from '@emobg/vue-base';

export default {
  name: 'DvlaComponent',
  directives: {
    Validate,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    cleanNumber: {
      type: Function,
      default: number => number,
    },
  },

  methods: {
    isBetweenValidLength(value) {
      const sizeValue = size(this.cleanNumber(value));
      const lowerThanFour = sizeValue < 4;
      const greaterThanEight = sizeValue > 8;
      const lowerThanFourError = lowerThanFour && this.$t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error');
      const greaterThanEightError = greaterThanEight && this.$t('complete_documentation.manual_upload.check_driving_license.dvla_input_max_length');

      return {
        isValid: !(lowerThanFour || greaterThanEight),
        message: lowerThanFourError || greaterThanEightError || '',
      };
    },
  },
};

</script>
