<template>
  <div data-test-id="driving-license-validation_view_validation-step1">
    <h2
      class="my-3 emobg-font-large emobg-color-secondary"
      v-html="$t('complete_documentation.manual_upload.check_driving_license.title')"
    />

    <p
      class="mb-6 font-regular emobg-font-medium emobg-color-ink"
      v-html="$t('complete_documentation.manual_upload.check_driving_license.note')"
    />

    <div
      :class="[
        'mb-6',
        {'pb-5': !isDvlaRequiredByCsOperatorAndCountry }
      ]"
    >
      <ui-loader v-if="isLoading" />
      <ui-select
        v-else
        :label="$t('common.country')"
        :options.prop="mappedCountries"
        :placeholder="$t('common.country_placeholder')"
        :searchbox="{
          placeholder: $t('common.country_placeholder'),
          threshold: 5,
        }"
        :value="country"
        class="w-100"
        data-test-id="driving_license_country-select"
        name="countriesList"
        @selectoption="({ detail }) => setCountrySelected(detail)"
      />
      <template v-if="isDvlaRequiredByCsOperatorAndCountry">
        <DvlaComponent
          :value="dvla"
          :clean-number="cleanDvlaNumber"
          class="w-50"
          @update:value="setDvla"
          @on:validate="setDvlaValid"
        />
        <a
          class="RegisterLayout__link pl-0 ml-0"
          href="https://www.viewdrivingrecord.service.gov.uk/driving-record/licence-number"
          target="blank"
        >
          {{ $t('complete_documentation.manual_upload.check_driving_license.get_my_dvla_code') }}
        </a>
      </template>
    </div>

    <div class="d-flex">
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :size="SIZES.large"
        data-test-id="driving-license-validation_button_later"
        @clickbutton="onClickDoItLater"
      >
        {{ $t('complete_documentation.manual_upload.presentation.do_it_later') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isButtonDisabled"
        :size="SIZES.large"
        class="w-30 ml-5"
        data-test-id="driving-license-validation_button_next"
        @clickbutton="goStepUploadDrivingLicense"
      >
        {{ $t('complete_documentation.manual_upload.check_driving_license.next_btn') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useTheme } from '@/composable/Theme/useTheme';
import DvlaComponent from './components/Dvla/DvlaComponent';
import * as DrivingLicenseRoadblockModule from '../DrivingLicenseRoadblockModule';

export default {
  name: 'DrivingLicenseValidationStepOneComponent',

  components: {
    DvlaComponent,
  },

  inheritAttrs: false,

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackPageView, trackModalView };
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState(DrivingLicenseRoadblockModule.NAMESPACE, {
      countriesList: state => state.list.countries,
      country: state => state.data.countrySelected,
      dvla: state => state.data.dvla || '',
      isDvlaRequired: state => state.data.dvlaRequired,
    }),

    ...mapGetters(DrivingLicenseRoadblockModule.NAMESPACE, {
      isDvlaRequiredByCsOperatorAndCountry:
          DrivingLicenseRoadblockModule.GETTERS.DVLA_IS_REQUIRED_BY_CS_OPERATOR_AND_COUNTRY,
      cleannedDvla:
          DrivingLicenseRoadblockModule.GETTERS.CLEANNED_DVLA,
      isButtonDisabled:
          DrivingLicenseRoadblockModule.GETTERS.SELECT_COUNTRY_DL_BUTTON_DISABLED,
    }),

    mappedCountries() {
      return this.countriesList.map(country => ({ value: country.code, label: country.name }));
    },
  },

  async created() {
    this.trackModalView({ modalName: GTMModalEvents.drivingLicenceValidationStep1 });
    this.isLoading = true;
    await this.fetchCountries();
    this.isLoading = false;
    this.fetchDrivingLicenseProviders();
  },

  methods: {
    cleanDvlaNumber: DrivingLicenseRoadblockModule.cleanDvlaNumber,

    ...mapMutations(DrivingLicenseRoadblockModule.NAMESPACE, {
      setCountrySelected:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_COUNTRY_SELECTED,
      setDvla:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_DVLA,
      setDvlaValid:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_DVLA_VALID,
    }),
    ...mapActions(DrivingLicenseRoadblockModule.NAMESPACE, {
      fetchCountries:
          DrivingLicenseRoadblockModule.ACTIONS.FETCH_COUNTRIES,
      fetchDrivingLicenseProviders:
          DrivingLicenseRoadblockModule.ACTIONS.FETCH_DRIVING_LICENSE_PROVIDERS,
      goStepUploadDrivingLicense:
          DrivingLicenseRoadblockModule.ACTIONS.GO_STEP_UPLOAD_DRIVING_LICENSE,
    }),
    // TODO: Move this method to be more flexible.
    // Maybe we can create a wrapper/provider to set some methods
    onClickDoItLater() {
      this.$fullScreen.close();
      this.trackPageView();
    },
  },
};

</script>
