<template>
  <div class="d-block mb-3 p-4 emobg-background-color-ground-light">
    <h3
      v-if="title"
      class="emobg-font-large mb-4"
      v-html="title"
    />

    <p>
      <a
        v-if="phone"
        :href="`tel:${phone}`"
        class="emobg-font-large"
        target="_blank"
      >
        {{ phone }}
      </a>
    </p>

    <p
      class="color-black"
      v-html="text"
    />

    <div
      class="emobg-color-ink-light"
      v-html="scheduleText"
    />

    <div
      v-if="schedule"
      class="emobg-color-ink-light"
      v-html="schedule"
    />
  </div>
</template>

<script>
export default {
  name: 'PendingValidationCardComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    scheduleText: {
      type: String,
      default: '',
    },
    schedule: {
      type: String,
      default: '',
    },
  },
};
</script>
