import replace from 'lodash/replace';
import { DATE_FORMAT } from '@emobg/web-utils';

export const DATETIME_FORMATS = {
  shortPrettyDatetime: `${DATE_FORMAT.timeOfDay} - DD ${DATE_FORMAT.monthShort} YY`,
  longPrettyDatetime: `${DATE_FORMAT.timeOfDay} D ${DATE_FORMAT.monthShort} YYYY`,
  longPrettyDateTimeWithHours: `${DATE_FORMAT.date} [at] ${DATE_FORMAT.timeOfDay}`,
  shortTimeDayMonth: `${DATE_FORMAT.timeOfDay} - DD ${DATE_FORMAT.monthShort}`,
  datetimepickerDisplay: `DD ${DATE_FORMAT.monthShort} YY`,
  emailReportBookingDates: `DD ${DATE_FORMAT.monthShort} YY ${DATE_FORMAT.timeOfDay}`,
  shortPrettyDateMonth: `Do ${DATE_FORMAT.monthShort}`,
  shortPrettyDateMonthYear: `Do ${DATE_FORMAT.monthShort} YY `,
  fullFormatTimezone: `${DATE_FORMAT.date}T${DATE_FORMAT.time}`,
  defaultDash: replace(DATE_FORMAT.default, /\w-/g, '/'),
  shortMonthLongYear: 'MM/yyyy',
  dobDayPilot: 'dd/MM/yyyy',
  shortDay: 'd',
  mediumDay: 'dd',
  shortDayLongMonthYear: `DD ${DATE_FORMAT.monthShort} YYYY`,
  LongMonthYear: `${DATE_FORMAT.monthShort} YYYY`,
};
