<template>
  <div class="container mt-5">
    <div class="AccountSettingsTab__Container d-flex flex-column ml-4">
      <h2 class="mb-3">
        {{ $t(`${getCurrentProfileAlias || PROFILE_TYPES.personal }_profile.notification.title`) }}
      </h2>

      <div class="emobg-font-medium emobg-color-ink mt-1">
        <p>{{ $t(`${getCurrentProfileAlias || PROFILE_TYPES.personal }_profile.notification.description_1`) }}</p>
      </div>

      <NotificationForm
        :current-email="currentEmail"
        :current-phone="currentPhone"
        :default-country="userCountry"
        :current-profile="getCurrentProfileAlias"
        :operator-service-email="getCurrentOperatorServiceEmail"
        :loading="loading"
        @submit-notifications="updateNotifications"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import NotificationForm from '@/components/Profile/NotificationsForm/NotificationForm';
import CHANNELS from '@/constants/notificationChannels';
import * as normalize from '@/utils/normalize';
import {
  channelsByType,
  getCurrentProfileAlias,
  getCurrentProfileUuid,
  refreshProfileInformation,
  updateNotificationChannel,
} from '@/stores/User/Profile/ProfileMapper';
import { getCurrentOperatorServiceEmail } from '@/stores/CSOperator/CSOperatorMapper';
import { userCountry } from '@/stores/User/UserData/UserDataMapper';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { PROFILE_TYPES } from '@/constants/profiles.const';

export default {
  name: 'NotificationsTab',
  components: {
    NotificationForm,
  },
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    return {
      notifyError,
      notifySuccess,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    channelsByType,
    getCurrentProfileUuid,
    getCurrentProfileAlias,
    userCountry,
    getCurrentOperatorServiceEmail,
    currentEmail() {
      return get(this.channelsByType(CHANNELS.email), 'value', '');
    },
    currentPhone() {
      return get(this.channelsByType(CHANNELS.phone), 'value', '');
    },
  },
  created() {
    this.CHANNELS = CHANNELS;
    this.PROFILE_TYPES = PROFILE_TYPES;
  },
  methods: {
    updateNotificationChannel,
    refreshProfileInformation,

    async updateNotifications({ email, phone }) {
      this.loading = true;

      const phoneNormalized = normalize.phoneNumber(phone);

      const updateChannels = [];

      if (!isEmpty(email) && email !== this.currentEmail) {
        updateChannels.push(this.updateNotificationChannel({
          profile: this.getCurrentProfileUuid,
          type: CHANNELS.email,
          value: email,
        }));
      }
      if (!isEmpty(phone) && phoneNormalized !== this.currentPhone) {
        updateChannels.push(this.updateNotificationChannel({
          profile: this.getCurrentProfileUuid,
          type: CHANNELS.phone,
          value: phoneNormalized,
        }));
      }

      try {
        await Promise.all(updateChannels);
        if (updateChannels.length) {
          await this.refreshProfileInformation();
        }
        this.notifySuccess({
          text: this.$t('modal.notifications.success_title'),
        });
      } catch (error) {
        this.notifyError({
          text: this.$t('notifications.whooops'),
          textAction: this.$t('buttons.close'),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>
