import { computed } from 'vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { userData as userDataGetter } from '@/stores/User/UserData/UserDataMapper';
import { getOperator } from '@/stores/Operator/OperatorMapper';
import KeyCardConfigure from '@/domains/Document/Keycard/Keycard';
import { FullScreenManagerInstance as fullScreen } from '@/components/motion-ui-legacy/MuiFullScreen/FullScreenManager';
import { getRequiredConfiguration } from '@/domains/Document/Keycard/helpers/requireOperatorConfig';

export const useKeycardRoadblock = () => {
  const userData = computed(userDataGetter);

  const showRoadblock = async config => {
    const {
      onKeycardRequested = noop,
      onKeycardLinked = noop,
      onKeycardPickedUp = noop,
      onBeforeClose = noop,
      onAfterClose = noop,
      onError = noop,
    } = config || {};

    const operatorUuid = get(userData, 'value.cs_operator_uuid');

    if (!operatorUuid) {
      throw new Error('operatorUuid does not exist');
    }

    const csOperator = await getOperator(operatorUuid);

    if (isEmpty(csOperator)) {
      throw new Error('operator does not exist');
    }

    const csOperatorConfig = getRequiredConfiguration(csOperator);

    fullScreen.show(KeyCardConfigure, {
      props: {
        user: {
          address: get(userData, 'value.address'),
          address_details: get(userData, 'value.address_details'),
          defaultCity: get(userData, 'value.defaultCity.uuid'),
          userUuid: get(userData, 'value.uuid'),
        },
        csOperatorConfig,
      },
      listeners: {
        'on:close': async () => {
          await onBeforeClose();
          fullScreen.close();
          await onAfterClose();
        },
        'on:keycard-linked': onKeycardLinked,
        'on:keycard-requested': onKeycardRequested,
        'on:keycard-picked-up': onKeycardPickedUp,
        'on:error': onError,
      },
    });
  };

  return {
    showRoadblock,
  };
};
