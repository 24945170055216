import { onBeforeMount, ref } from 'vue';
import get from 'lodash/get';
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

export const useMaps = () => {
  const googleMapsAutocompleteService = ref(null);
  const googleMapsGeocoderService = ref(null);

  onBeforeMount(async () => {
    await googleMapsLoader.createInstance();
    const maps = get(googleMapsLoader.getInstance(), 'maps');
    if (maps) {
      googleMapsAutocompleteService.value = () => new maps.places.AutocompleteService();
      googleMapsGeocoderService.value = () => new maps.Geocoder();
    }
  });

  return {
    googleMapsAutocompleteService,
    googleMapsGeocoderService,
  };
};
