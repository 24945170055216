<template>
  <div class="NotificationForm">
    <ui-form validate>
      <ui-validate
        class="d-flex flex-column"
        @status="event => formStatus = event.detail"
      >
        <AlertComponent
          v-if="!hasBusinessProfile"
          :type="ALERT_TYPES.informative"
          class="d-block w-100 mt-1 mb-3"
          data-test-id="alert-block"
        >
          <div v-html="formatedCsOperatorMailto" />
        </AlertComponent>
        <ui-text-input
          v-validate.input="{
            isRequired: true,
            isEmail: {
              message: $t('email_input.invalid_email'),
            },
          }"
          name="email"
          :label="$t(`${currentProfile}_profile.notification.notif_email`)"
          :placeholder="$t(`${currentProfile}_profile.notification.notif_email`)"
          :value="email"
          :type="INPUT_TYPES.email"
          :disabled="!hasBusinessProfile"
          data-test-id="email-input"
          data-cs-mask
          class="d-block mt-1 mb-3"
          @changevalue="({ detail }) => email = detail"
        />

        <PhoneNumberWrapper
          :label="$t('refactor.company_signup.fields.phone')"
          :placeholder="$t('refactor.company_signup.fields.phone')"
          :value="phone"
          :country="defaultCountry"
          :disabled="!hasBusinessProfile"
          data-test-id="phone-input"
          class="d-block mt-1 mb-3"
          @change="value => phone = value"
          @isValid="isValid => isPhoneNumberValid = isValid"
        />

        <ui-button
          v-bind="fetchButtonSpecs()"
          :type="BUTTON_TYPES.submit"
          :disabled="!channelsChanged || !isPhoneNumberValid || !formStatus.isValid"
          :loading="loading"
          class="d-block mt-1"
          data-test-id="update-notifications-button"
          @clickbutton="$emit('submit-notifications', { email, phone })"
        >
          {{ $t('personal_profile.action.save') }}
        </ui-button>
      </ui-validate>
    </ui-form>
  </div>
</template>

<script>
import clone from 'lodash/clone';
import { BUTTON_TYPES, INPUT_TYPES, Validate } from '@emobg/vue-base';
import { COUNTRIES_ISO_CODES } from '@emobg/web-utils';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import AlertComponent from '@/components/Alert/AlertComponent';
import PhoneNumberWrapper from '@/components/PhoneNumberWrapper/PhoneNumberWrapper';
import { composeAlertEmailToLinkData } from '@/helpers/user/personalInformation/personalInformationHelpers';
import { hasBusinessProfile } from '@/stores/User/Profile/ProfileMapper';
import { useTheme } from '@/composable/Theme/useTheme';
import { PROFILE_TYPES } from '@/constants/profiles.const';

export default {
  name: 'NotificationForm',
  components: {
    PhoneNumberWrapper,
    AlertComponent,
  },
  directives: {
    Validate,
  },
  props: {
    currentEmail: {
      type: String,
      default: '',
    },
    currentPhone: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: COUNTRIES_ISO_CODES.spain,
    },
    operatorServiceEmail: {
      type: String,
      default: '',
    },
    currentProfile: {
      type: String,
      default: PROFILE_TYPES.personal,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },
  data() {
    return {
      formStatus: {},
      phone: undefined,
      email: undefined,
      isPhoneNumberValid: true,
    };
  },
  computed: {
    hasBusinessProfile,
    formatedCsOperatorMailto() {
      return composeAlertEmailToLinkData({ serviceEmail: this.operatorServiceEmail });
    },
    channelsChanged() {
      return this.email !== this.currentEmail || this.phone !== this.currentPhone;
    },
  },
  created() {
    this.email = clone(this.currentEmail);
    this.phone = clone(this.currentPhone);
    this.ALERT_TYPES = ALERT_TYPES;
    this.INPUT_TYPES = INPUT_TYPES;
    this.BUTTON_TYPES = BUTTON_TYPES;
  },
};
</script>
