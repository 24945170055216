<template>
  <div data-test-id="driving-license-validation_view_validation-step3">
    <h2
      class="my-3 emobg-font-large emobg-color-secondary"
      v-html="$t('complete_documentation.manual_upload.driving_license_pending.title')"
    />

    <p
      class="mb-6 font-regular emobg-font-medium emobg-color-ink"
      v-html="$t('complete_documentation.manual_upload.driving_license_pending.subtitle')"
    />

    <PendingValidationCardComponent
      v-if="userHasBadge"
      class="mb-4"
      :title="$t('complete_documentation.manual_upload.driving_license_pending.speed_up_card.title')"
      :phone="csOperator.customer_service_phone"
      :text="$t('complete_documentation.manual_upload.driving_license_pending.speed_up_card.call_us')"
      :schedule-text="$t('complete_documentation.manual_upload.driving_license_pending.speed_up_card.customer_care_schedule')"
      :schedule="csOperator.customer_service_opening_hours"
    />

    <div class="d-flex">
      <ui-button
        v-bind="fetchButtonSpecs()"
        data-test-id="driving-license-validation_button_next"
        class="mr-4"
        @clickbutton="onClickNextStep"
      >
        {{ userHasBadge ? $t('buttons.done') : $t('buttons.next_step') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useTheme } from '@/composable/Theme/useTheme';

import * as DrivingLicenseRoadblockModule from '../DrivingLicenseRoadblockModule';
import PendingValidationCardComponent from './components/PendingValidationCardComponent/PendingValidationCardComponent';

export default {
  name: 'DrivingLicenseValidationStepThreeComponent',

  components: {
    PendingValidationCardComponent,
  },

  inheritAttrs: false,

  props: {
    userHasBadge: {
      type: Boolean,
      required: true,
      default: true,
    },
    csOperator: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackPageView, trackModalView };
  },

  created() {
    this.trackModalView({ modalName: GTMModalEvents.drivingLicenceValidationStep3 });
  },

  methods: {
    ...mapMutations(DrivingLicenseRoadblockModule.NAMESPACE, {
      setDrivingLicenseFinishedWithBadge: DrivingLicenseRoadblockModule.MUTATIONS.SET_DRIVING_LICENSE_FINISHED_WITH_BADGE,
    }),
    onClickNextStep() {
      if (this.userHasBadge) {
        this.setDrivingLicenseFinishedWithBadge(true);
      }

      this.trackPageView();
      this.$emit('on:close');
    },
  },

};

</script>
