export const LOCATION_TYPES = {
  geofence: 'geofence',
  parking: 'parking',
  stack: 'stack',
  streetParking: 'streetParking',
};

export const LOCATION_KEYS = {
  uuid: 'uuid',
  name: 'name',
};
