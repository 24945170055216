var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-validate',{on:{"status":({ detail }) => _vm.$emit('on:validate', detail.isValid)}},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
      isRequired: {
        message: _vm.$t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),
        whiteSpaceMessage: _vm.$t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),
      },
      isBetweenValidLength: _vm.isBetweenValidLength,
    }),expression:"{\n      isRequired: {\n        message: $t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),\n        whiteSpaceMessage: $t('complete_documentation.manual_upload.check_driving_license.dvla_input_empty_error'),\n      },\n      isBetweenValidLength,\n    }",modifiers:{"blurinput":true}}],attrs:{"value":_vm.value,"placeholder":_vm.$t('complete_documentation.manual_upload.check_driving_license.dvla_input_placeholder'),"name":"dvla"},on:{"changevalue":({ detail }) => _vm.$emit('update:value', detail)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }