<script>
import get from 'lodash/get';
import { MIN_PHONE_NUMBER_LENGTH } from './constants';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: null,
    },
    blurValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: true,
      isValidated: false,
      phoneNumber: null,
    };
  },
  computed: {
    phoneNumberError() {
      if (this.isValid) {
        return '';
      }

      const phoneNumberLength = get(this, 'phoneNumber.length') || 0;

      return phoneNumberLength <= MIN_PHONE_NUMBER_LENGTH
        ? this.$t('refactor.company_signup.errors.mandatory')
        : this.$t('refactor.company_signup.errors.invalid_phonenumber');
    },
  },
  methods: {
    get,
    onChangePhoneNumber(event) {
      this.isValidated = event.isValidated;
      this.isValid = event.isValid;
      this.phoneNumber = event.completePhoneNumber;
      this.$emit('isValid', event.isValid);
      this.$emit('change', event.completePhoneNumber);
    },
  },
};
</script>
<template>
  <div class="PhoneNumberWrapper">
    <ui-phone-input
      :label="label"
      :country="country"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :blurvalidation="blurValidation"
      data-cs-mask
      @changephone="({ detail }) => onChangePhoneNumber(detail)"
    />
    <div
      v-if="isValidated && phoneNumberError"
      class="pl-1 pt-1 emobg-font-x-small emobg-color-danger"
    >
      {{ phoneNumberError }}
    </div>
  </div>
</template>
