<template>
  <LayoutTemplate :left-image="userSignUpImage">
    <div class="full-width mt-4 pt-6 d-flex justify-content-center">
      <div class="Step__form">
        <Component
          :is="component"
          :user-uuid="userUuid"
          :user-has-badge="hasBadge"
          :cs-operator="csOperator"
          @on:close="$emit('on:close')"
          @on:driving-license-created="$emit('on:driving-license-created')"
          @on:error="error => $emit('on:error', error)"
        />
      </div>
    </div>
  </LayoutTemplate>
</template>

<script>

import { mapMutations, mapState } from 'vuex';
import LayoutTemplate from '@Account/SignUp/Templates/SignUpTemplate';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import * as DrivingLicenseRoadblockModule from './DrivingLicenseRoadblockModule';

export default {
  name: 'DrivingLicenseRoadblockComponent',

  components: {
    LayoutTemplate,
  },

  props: {
    userUuid: {
      type: String,
      required: true,
    },
    csOperator: {
      type: Object,
      required: true,
    },
    hasBadge: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(DrivingLicenseRoadblockModule.NAMESPACE, {
      currentStep: state => state.data.step,
    }),
    userSignUpImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2c),
    /* eslint-disable import/no-dynamic-require, global-require  */
    component() {
      const { default: component } = require(`./DrivingLicenseValidation/DrivingLicenseValidation${this.currentStep}Component`);

      return component;
    },
    /* eslint-enable import/no-dynamic-require, global-require */
  },

  created() {
    this.resetDLRoadblockState();
    this.setDvlaIsRequiredByCsOperator(this.csOperator.configuration.show_dlva_code);
  },

  methods: {
    ...mapMutations(DrivingLicenseRoadblockModule.NAMESPACE, {
      setDvlaIsRequiredByCsOperator: DrivingLicenseRoadblockModule.MUTATIONS.SET_DVLA_REQUIRED,
      resetDLRoadblockState: DrivingLicenseRoadblockModule.MUTATIONS.RESET_DATA,
    }),
  },
};

</script>
